import Layout from './layout/Layout.js';
import { projectSliderActive, serviceThreeSlider } from "./sliderProps";
import { useState } from 'react';
import Slider from "react-slick";
import VideoPlayer from './components/VideoPlayer';


const Index = () => {
  const [play, setPlay] = useState(null);
  const handleOpenVideo = (url) => {
    setPlay(url);
  };
  return (
    <Layout header={1}>
      <section className="hero-area bgc-gray rel z-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 align-self-center">
              <div className="hero-content pt-115 pb-125 rpb-0 wow fadeInUp delay-0-4s">
                <h1>AI創作展示平台</h1>
                <p>
                  我們的平台是一個專門為AI創作者提供的社交媒體市場，他們可以在該平台上展示和銷售他們的AI偶像、AI生成的產品、設計和服務。該平台的核心價值在於連接AI創作者和對其創新產品感興趣的用戶。
                </p>
                <div className="hero-shapes">
                  <img
                    className="shape one"
                    src="assets/images/shapes/dabble-plus.png"
                    alt="Shape"
                  />
                  <img
                    className="shape two"
                    src="assets/images/shapes/dabble-plus.png"
                    alt="Shape"
                  />
                  <img
                    className="shape three"
                    src="assets/images/shapes/plus.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-end">
              <div className="hero-images mt-80 wow fadeInLeft">
                <img src="assets/images/hero/hero-one.png" alt="Hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shapes">
          <img
            className="shape bg-lines"
            src="assets/images/shapes/hero-bg-line-shapes.png"
            alt="Shape"
          />
          <img
            className="shape right-shape wow fadeInRight delay-0-8s"
            src="assets/images/shapes/hero-right-shape.png"
            alt="Shape"
          />
        </div>
      </section>
      <section className="about-area mt-60 pt-150 pb-130 rpb-100 rel z-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-content rmb-65 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-30">
                  <span className="sub-title mb-15">Solution</span>
                  <h2>解決方案</h2>
                </div>
                <p className="mb-45">
                  我們的AI創作平台為這個問題提供了解決方案。無論你是AI偶像的創作者，還是AI產品和設計的創作者，你都可以在我們的平台上輕鬆地展示你的作品，吸引和建立客戶群，並通過推廣和購物廣告獲得收入。我們的平台不僅讓創作者有機會讓他們的作品獲得更廣泛的曝光，而且還讓他們的AI產品和設計獲得了真正的市場價值。加入我們的平台，讓你的AI創作照亮世界。
                </p>
                <div className="row no-gap for-active">
                  <div className="col-sm-6">
                    <div className="service-item active">
                      <div className="icon">
                        <img
                          src="assets/images/services/icon1.png"
                          alt="Icon"
                        />
                      </div>
                      <h4>
                        問題
                      </h4>
                      <p>
                        在當今的數字世界中，AI創作者創造出各種獨特的AI產品，設計和服務，但往往缺乏一個統一的平台來展示他們的作品，吸引客戶，並實現商業化。
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="service-item">
                      <div className="icon">
                        <img
                          src="assets/images/services/icon2.png"
                          alt="Icon"
                        />
                      </div>
                      <h4>
                        加劇
                      </h4>
                      <p>
                        創作者們花費大量的時間和努力來創造出這些創新的AI產品和設計，然而，他們的作品往往被埋沒在互聯網的混亂中，沒有得到應有的關注和賞識。此外，他們往往難以找到適合他們AI產品和設計的商業機會，從而讓他們的創作獲得商業價值。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-images">
                <div className="top-part">
                  <img
                    className="wow fadeInRight delay-0-3s"
                    src="assets/images/about/about1.png"
                    alt="About"
                  />
                  <img
                    className="wow zoomIn delay-0-5s"
                    src="assets/images/about/about-logo.png"
                    alt="About"
                  />
                </div>
                <div className="bottom-part">
                  <img
                    className="wow fadeInDown delay-0-5s"
                    src="assets/images/about/about-dots.png"
                    alt="About"
                  />
                  <img
                    className="wow fadeInDown delay-0-3s"
                    src="assets/images/about/about2.png"
                    alt="About"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-area overflow-hidden bgc-lighter pt-130 rpt-100 rel z-1">
        <div className="container">
          <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-15">Learn Our Project</span>
            <h2>我們的平台</h2>
          </div>
          <Slider {...projectSliderActive} className="project-slider-active">
            <div className="project-slider-item">
              <div className="video" onClick={() => {
                handleOpenVideo('assets/video/1.mp4');
              }}>
                <img
                  src="assets/images/projects/project-video.png"
                  alt="Video"
                />
                <a
                  className="video-play"
                  tabIndex={-1}
                >
                  <i className="fas fa-play" />
                </a>
              </div>
              <div className="content">
                <h4>
                  在這些業務範圍中，我們的目標是為AI創作者提供一個讓他們的作品獲得更大曝光和商業價值的平台，同時也為用戶提供一個能夠發現和購買AI創作的地方。
                </h4>
                <p>
                  我們的平台可以通過多種方式獲得收入，包括從推廣和購物廣告中獲得的收入，合作伙伴關係和附屬關係的收入，以及可能的訂閱費用。
                </p>
                <p>
                  這種商業模式能夠使AI創作者的作品獲得更大的曝光和商業價值，同時也為用戶提供了一個發現和購買AI創作的平台。
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="project-shapes">
          <img
            className="shape one"
            src="assets/images/shapes/project-left.png"
            alt="shape"
          />
          <img
            className="shape two"
            src="assets/images/shapes/project-right.png"
            alt="shape"
          />
        </div>
      </section>
      <section className="services-area bgc-gray text-white pt-75 pb-10 rel z-1">
        <div className="container">
          <div className="row medium-gap">
            <div className="col-xl-4 col-md-6">
              <div className="section-title mb-60 wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">Latest Services</span>
                <h2>我們的服务</h2>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-two-item wow fadeInUp delay-0-4s">
                <div className="icon">
                  <i className="flaticon-networking" />
                </div>
                <div className="content">
                  <h4>
                    AI創作展示平台
                  </h4>
                  <p>
                    我們的業務的主要範圍是提供一個平台，讓AI創作者能夠展示和銷售他們的AI創作。這包括AI偶像、AI生成的產品、設計和服務。創作者可以在我們的平台上創建他們的個人檔案，展示他們的作品，並吸引用戶的關注。
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-two-item wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon-coding" />
                </div>
                <div className="content">
                  <h4>
                    用戶參與和互動
                  </h4>
                  <p>
                    我們的平台還為用戶提供了一個互動和參與的機會。用戶可以搜索和瀏覽他們感興趣的AI創作，可以“喜歡”和“分享”他們喜歡的創作，並且可以在我們的平台上跟隨他們最喜歡的創作者。
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-two-item wow fadeInUp delay-0-3s">
                <div className="icon">
                  <i className="flaticon-app-development" />
                </div>
                <div className="content">
                  <h4>
                    廣告和銷售
                  </h4>
                  <p>
                    我們的業務範圍還包括推廣和銷售。創作者可以選擇將他們的AI創作轉化為推廣或購物廣告，這將在我們的平台上提供更大的曝光，並提供銷售機會。
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-two-item wow fadeInUp delay-0-5s">
                <div className="icon">
                  <i className="flaticon-logo" />
                </div>
                <div className="content">
                  <h4>
                    合作伙伴和附屬關係
                  </h4>
                  <p>
                    我們與各種品牌和AI以及科技領域的影響者建立合作伙伴和附屬關係。這讓他們可以利用我們的平台和其上的AI創作來進行市場活動，同時也可以為我們的平台帶來更多的曝光和知名度。
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-two-item wow fadeInUp delay-0-7s">
                <div className="icon">
                  <i className="flaticon-seo" />
                </div>
                <div className="content">
                  <h4>
                    訂閱服務
                  </h4>
                  <p>
                    我們提供訂閱服務給創作者和用戶，他們可以通過支付每月或每年的費用獲得我們平台的特殊福利，如優先訪問新產品和服務，或獲得特殊的優惠和折扣。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-grid-area rel z-2 py-130 rpy-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6" style={{
              cursor: 'pointer'
            }} onClick={() => {
              handleOpenVideo('assets/images/home/demo1.mp4');
            }}>
              <div className="cursor-pointer demo-img-box">
                <img
                  src="assets/images/projects/project-grid1.png"
                  alt="Project Grid"
                />
              </div>
              <div className="project-grid-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <h4>
                    DEMO作品
                  </h4>
                  <a className="detail-btn">
                    <i className="far fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6" style={{
              cursor: 'pointer'
            }} onClick={() => {
              handleOpenVideo('assets/images/home/demo2.mp4');
            }}>
              <div className="cursor-pointer demo-img-box">
                <img
                  src="assets/images/projects/project-grid2.jpg"
                  alt="Project Grid"
                />
              </div>
              <div className="project-grid-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <h4>
                    DEMO作品
                  </h4>
                  <a className="detail-btn">
                    <i className="far fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6" style={{
              cursor: 'pointer'
            }} onClick={() => {
              handleOpenVideo('assets/images/home/demo3.mp4');
            }}>
              <div className="cursor-pointer demo-img-box">
                <img
                  src="assets/images/projects/project-grid3.jpg"
                  alt="Project Grid"
                />
              </div>
              <div className="project-grid-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <h4>
                    DEMO作品
                  </h4>
                  <a className="detail-btn">
                    <i className="far fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us-area py-130 rpy-100 rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="section-title text-center mb-45 wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">Why Choose Us</span>
                {/* <h2>
                  我們的平台是一個專門為AI創作者提供的社交媒體市場，他們可以在該平台上展示和銷售他們的AI偶像、AI生成的產品、設計和服務。該平台的核心價值在於連接AI創作者和對其創新產品感興趣的用戶。
                </h2> */}
                <h2>商業模式</h2>
              </div>
            </div>
          </div>
          <div className="why-choose-tab">
            <ul className="nav nav-pills nav-fill mb-80 rmb-50 wow fadeInUp delay-0-4s">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#wc-tap1"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <i className="flaticon-creativity" />{" "}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}>
                    <span>AI展示</span>
                    <p style={{
                      width: '210px',
                      fontSize: '13px',
                      lineHeight: '20px',
                      color: '#838694',
                      marginTop: '10px'
                    }}>
                      創作者可以在我們的平台上展示他們的AI作品，這可以是AI偶像，或者AI生成的產品、設計或服務。他們可以創建一個詳細的個人檔案，展示他們的作品，並吸引用戶的關注。
                    </p>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#wc-tap1"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <i className="flaticon-test" />{" "}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}>
                    <span>用戶參與</span>
                    <p style={{
                      width: '200px',
                      fontSize: '13px',
                      lineHeight: '20px',
                      color: '#838694',
                      marginTop: '10px'
                    }}>
                      用戶可以搜索和瀏覽他們感興趣的AI創作，並與創作者互動。他們可以“喜歡”和“分享”他們喜歡的AI創作，並在我們的平台上跟隨他們最喜歡的創作者。
                    </p>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#wc-tap1"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <i className="flaticon-cyber-security-1" />{" "}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}>
                    <span>推廣和購物廣告</span>
                    <p style={{
                      width: '200px',
                      fontSize: '13px',
                      lineHeight: '20px',
                      color: '#838694',
                      marginTop: '10px'
                    }}>
                      創作者可以選擇將他們的AI創作轉化為推廣或購物廣告。這意味著他們的創作將在我們的平台上獲得更大的曝光，並可能直接從平台上的銷售中獲利。
                    </p>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#wc-tap1"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <i className="flaticon-support" />{" "}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}>
                    <span>合作伙伴關係和附屬關係</span>
                    <p style={{
                      width: '200px',
                      fontSize: '13px',
                      lineHeight: '20px',
                      color: '#838694',
                      marginTop: '10px'
                    }}>
                      我們的平台可以與品牌進行合作，讓他們利用AI創作進行他們的市場活動或活動。此外，我們的平台也可以與AI和科技領域的影響者進行合作，以增加其知名度。
                    </p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="what-we-provide overflow-hidden py-130 rpy-100 rel z-1">
        <div className="container">
          <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-10">What We Provides</span>
            <h2>Digital Core Services</h2>
          </div>
          <Slider {...serviceThreeSlider} className="service-three-slider">
            <div className="service-item-three wow fadeInUp delay-0-2s">
              <div className="image">
                <img
                  src="assets/images/services/service-three1.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three1.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">01</span>
                  <div className="icon">
                    <i className="flaticon-coding-1" />
                  </div>
                  <h4>
                    推廣和購物廣告
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    創作者可以選擇將他們的AI創作轉化為推廣或購物廣告。我們將從每筆銷售中收取一定比例的佣金，作為使用我們的平台進行銷售的費用。此外，如果創作者希望他們的創作獲得更大的曝光，他們可以選擇支付額外的費用以在我們的平台上進行精選推廣。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three active wow fadeInUp delay-0-4s">
              <div className="image">
                <img
                  src="assets/images/services/service-three2.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three2.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">02</span>
                  <div className="icon">
                    <i className="flaticon-layers" />
                  </div>
                  <h4>
                    合作伙伴關係和附屬關係
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    我們的平台可以與品牌進行合作，讓他們利用AI創作進行他們的市場活動或活動。我們將從這種合作中獲得收入，包括固定的合作費用和/或從每筆由此產生的銷售中獲得的佣金。此外，我們的平台也可以與AI和科技領域的影響者進行合作，我們將從這種合作中獲得收入。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three wow fadeInUp delay-0-6s">
              <div className="image">
                <img
                  src="assets/images/services/service-three3.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three3.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">03</span>
                  <div className="icon">
                    <i className="flaticon-cyber-security-1" />
                  </div>
                  <h4>
                    訂閱費用
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    我們可以提供一個訂閱計劃，用戶可以支付每月或每年的費用來獲得我們平台的特殊福利，例如獲得對新產品和服務的優先訪問，或者獲得特殊的優惠和折扣。此外，我們也可以為創作者提供一個訂閱計劃，他們可以獲得額外的平台功能，例如進行更多的推廣，或者接觸更廣泛的客戶群。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three wow fadeInUp delay-0-2s">
              <div className="image">
                <img
                  src="assets/images/services/service-three4.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three4.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">04</span>
                  <div className="icon">
                    <i className="flaticon-coding-1" />
                  </div>
                  <h4>
                    交易費用
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    對於平台上的每筆交易，我們都可以收取一定的交易費用。這可以包括買賣雙方進行的每筆交易，或者創作者與品牌或影響者之間的每筆交易。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three active">
              <div className="image">
                <img
                  src="assets/images/services/service-three5.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three5.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">05</span>
                  <div className="icon">
                    <i className="flaticon-layers" />
                  </div>
                  <h4>
                    AI偶像的图钉
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    与Pinterest的图钉类似，允许用户为他们的AI偶像创建图钉。这些图钉可以包含展示偶像的图片、视频或其他媒体，以及描述。用户可以在他们的信息流中浏览这些图钉，或通过搜索功能来发现它们。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three">
              <div className="image">
                <img
                  src="assets/images/services/service-three6.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three6.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">06</span>
                  <div className="icon">
                    <i className="flaticon-cyber-security-1" />
                  </div>
                  <h4>
                    画板
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    允许用户为不同类别的AI偶像（如“音乐AI偶像”、“艺术AI偶像”等）策划“画板”。这可以帮助用户管理和展示他们最喜欢的偶像，也可以帮助其他用户发现它们。
                  </p>
                </div>
              </div>
            </div>

            <div className="service-item-three wow fadeInUp delay-0-2s">
              <div className="image">
                <img
                  src="assets/images/services/service-three7.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three7.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">07</span>
                  <div className="icon">
                    <i className="flaticon-coding-1" />
                  </div>
                  <h4>
                    推广的AI偶像
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    与Pinterest的推广图钉类似，允许创作者支付费用让他们的AI偶像在平台上更突出地显示。这可能涉及在显眼的位置（如主页或搜索结果的顶部）展示偶像。你可以按点击付费或按展示次数付费的方式向创作者收费。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three active">
              <div className="image">
                <img
                  src="assets/images/services/service-three8.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three8.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">08</span>
                  <div className="icon">
                    <i className="flaticon-layers" />
                  </div>
                  <h4>
                    AI偶像购物广告
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    如果AI偶像可以出租或购买，让创作者将他们的AI偶像图钉变成购物广告。用户可以点击这些广告来租用或购买AI偶像。
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-three">
              <div className="image">
                <img
                  src="assets/images/services/service-three9.png"
                  alt="Service"
                />
                <a
                  className="plus"
                  href="assets/images/services/service-three9.png"
                >
                  <i className="fal fa-plus" />
                </a>
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">09</span>
                  <div className="icon">
                    <i className="flaticon-cyber-security-1" />
                  </div>
                  <h4>
                    用户参与
                  </h4>
                </div>
                <div className="bottom-part">
                  <p>
                    加入类似Pinterest的用户参与功能，如“喜欢”和“转发”AI偶像的能力。还可以包括评论部分，用户可以在其中讨论和对AI偶像进行反馈。
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="about-bg-shape">
          <img src="assets/images/background/what-we-provide.png" alt="Shape" />
        </div>
      </section>


      {
        play && (
          <VideoPlayer url={play} close={() => {
            setPlay(null);
          }} />
        )
      }
    </Layout>
  );
};
export default Index;
