import { stickyNav } from "../../utils/index";
import { useEffect } from "react";
import Header1 from "./Header1";

const Header = ({ header }) => {
  useEffect(() => {
    stickyNav();
  }, []);

  switch (header) {
    case 1:
      return <Header1 />;
    default:
      return <Header1 />;
  }
};
export default Header;
