
const Footer = () => {
  return (
    <footer className="main-footer bgc-gray footer-white rel z-1">

      <div className="container">
        <div className="row medium-gap">
          <div className="col-xl-3 col-sm-6">
            <div className="footer-widget widget_about wow fadeInUp delay-0-2s">
              <div className="footer-logo mb-30" style={{
                background: 'rgba(0,0,0,0)'
              }}>
                <a href="#">
                  <img src="assets/images/logos/logo-three.png" alt="Logo" />
                </a>
              </div>
              <p>
                加入我們的平台，讓你的AI創作照亮世界。
              </p>
              {/* <Link legacyBehavior href="/about">
                <a className="read-more">
                  Learn More Us <i className="fas fa-long-arrow-right" />
                </a>
              </Link> */}
            </div>
          </div>


        </div>
      </div>
      <div className="footer-shapes">
        <img
          className="shape one"
          src="assets/images/footer/footer-bg-weve-shape.png"
          alt="Shape"
        />
        <img
          className="shape two"
          src="assets/images/footer/footer-bg-line-shape.png"
          alt="Shape"
        />
        <img
          className="shape three wow fadeInRight delay-0-8s"
          src="assets/images/footer/footer-right.png"
          alt="Shape"
        />
      </div>
    </footer>
  );
};
export default Footer;
